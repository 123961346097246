import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Helmet } from "react-helmet";

function BAA() {
	useEffect(() => {
		setTitle(`MyHealthily Business Associate Agreement`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>Business Associate Agreement</h1>
						</div>
					</div>
				</div>
			</section>
			
			{/* One Column */}
			<div className="container pt-3 pb-2">
				<div className="row pt-2">
					<div className="col">
						<p>This Agreement (“Agreement”) is made and entered into at the date and time your MyHealthily
							account is created and is between you (“Covered Entity”) and MyHealthily Insurance Solutions,
							LLC (“Business Associate” or “MyHealthily”), a Delaware limited liability company.</p>
						<p>WHEREAS, Business Associate is in the business of providing Licensed Insurance Agent/Broker
							Services (“Offering”); and</p>
						<p>WHEREAS, Covered Entity wishes to engage, or has engaged, Business Associate in connection
							with said Offering,</p>
						<p>NOW, THEREFORE, in consideration of the premises and mutual promises herein contained, it
							is agreed as follows:</p>
						<h3>1. Definitions.</h3>
						<p>Terms used, but not otherwise defined in this Agreement, shall have the same meaning as those
							terms in the Privacy Rule, Security Rule, and HITECH Act.</p>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>Agent or Broker. “Agent” or “Broker” shall have the meaning as determined in accordance
								with the federal common law of agency.
							</li>
							<li>IBreach. “Breach” shall have the same meaning as the term “breach” in 45 CFR § 164.402.
							</li>
							<li>Business Associate. “Business Associate” shall mean MyHealthily Insurance Solutions,
								LLC.
							</li>
							<li>Covered Entity. “Covered Entity” shall mean active user of MyHealthily who registers for
								an account and agrees to the terms of service, and who is a “covered entity” as defined
								in 45 CFR § 160.103.
							</li>
							<li>Data Aggregation. “Data Aggregation” shall have the same meaning as the term “data
								aggregation” in 45 CFR § 164.501.
							</li>
							<li>Designated Record Set. “Designated Record Set” shall have the same meaning as the term
								“designated record set” in 45 CFR § 164.501.
							</li>
							<li>Disclosure. “Disclosure” and “Disclose” shall have the same meaning as the term
								“Disclosure” in 45 CFR § 160.103.
							</li>
							<li>Electronic Health Record. “Electronic Health Record” shall have the same meaning as the
								term in Section 13400 of the HITECH Act.
							</li>
							<li>Health Care Operations. “Health Care Operations” shall have the same meaning as the term
								“health care operations” in 45 CFR § 164.501.
							</li>
							<li>HIPAA Rules. “HIPAA Rules” shall mean the Privacy, Security, Breach Notification, and
								Enforcement Rules at 45 CFR Part 160 and Part 164.
							</li>
							<li>HITECH Act. “HITECH Act” shall mean The Health Information Technology for
								Economic and Clinical Health Act, part of the American Recovery and Reinvestment Act
								of 2009 (“ARRA” or “Stimulus Package”), specifically DIVISION A: TITLE XIII Subtitle
								D—Privacy, and its corresponding regulations as enacted under the authority of the Act.
							</li>
							<li>Individual. “Individual” shall have the same meaning as the term “individual” in 45 CFR §
								160.103 and shall include a person who qualifies as a personal representative in
								accordance
								with 45 CFR § 164.502(g).
							</li>
							<li>Minimum Necessary. “Minimum Necessary” shall mean the Privacy Rule Standards
								found at §164.502(b) and § 164.514(d)(1).
							</li>
							<li>Privacy Rule. “Privacy Rule” shall mean the Standards for Privacy of Individually
								Identifiable Health Information at 45 CFR Part 160 and Part 164, Subparts A and E.
							</li>
							<li>Protected Health Information. “Protected Health Information” shall have the same
								meaning as the term “protected health information” in 45 CFR § 160.103, limited to the
								information created, received, maintained or transmitted by Business Associate on behalf
								of Covered Entity.
							</li>
							<li>Required By Law. “Required By Law” shall have the same meaning as the term
								“required by law” in 45 CFR § 164.103.
							</li>
							<li>Secretary. “Secretary” shall mean the Secretary of the Department of Health and Human
								Services or his or her designee.
							</li>
							<li>Security Incident. “Security Incident” shall have the same meaning as the term “Security
								Incident” in 45 CFR § 164.304.
							</li>
							<li>Security Rule. “Security Rule” shall mean the Standards for Security of Electronic
								Protected Health Information at 45 C.F.R. parts § 160 and § 164, Subparts A and C.
							</li>
							<li>Subcontractor. “Subcontractor” shall mean a person or entity “that creates, receives,
								maintains, or transmits protected health information on behalf of a business associate”
								and who is now considered a business associate, as the latter term is defined in 45 CFR §
								160.103.
							</li>
							<li>Subject Matter. “Subject Matter” shall mean compliance with the HIPAA Rules and with
								the HITECH Act.
							</li>
							<li>Unsecured Protected Health Information. “Unsecured Protected Health Information”
								shall have the same meaning as the term “unsecured protected health information” in 45
								CFR § 164.402.
							</li>
							<li>Use. “Use” shall have the same meaning as the term “Use” in 45 CFR § 164.103.</li>
						</ol>
						
						<h3>2. Obligations and Activities of Business Associate.</h3>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>Business Associate agrees to not Use or Disclose Protected Health Information other than
								as permitted or required by this Agreement or as Required By Law.
							</li>
							<li>Business Associate agrees to use appropriate safeguards to prevent Use or Disclosure of
								Protected Health Information other than as provided for by this Agreement. Business
								Associate further agrees to implement administrative, physical and technical safeguards
								that reasonably and appropriately protect the confidentiality, integrity and availability
								of any electronic Protected Health Information, as provided for in the Security Rule and
								as mandated by Section 13401 of the HITECH Act.
							</li>
							<li>Business Associate agrees to mitigate, to the extent practicable, any harmful effect that
								is known to Business Associate of a Use or Disclosure of Protected Health Information by
								Business Associate in violation of the requirements of this Agreement. Business
								Associate further agrees to report to Covered Entity any Use or Disclosure of Protected
								Health Information not provided for by this Agreement of which it becomes aware, and
								in a manner as prescribed herein.
							</li>
							<li>If the Breach, as discussed in paragraph 2(c), pertains to Unsecured Protected Health
								Information, then Business Associate agrees to report any such data Breach to Covered
								Entity within ten (10) business days of discovery of said Breach; all other compromises
								of Protected Health Information shall be reported to Covered Entity within twenty (20)
								business days of discovery. Business Associate further agrees, consistent with Section
								13402 of the HITECH Act, to provide Covered Entity, via email or phone call, with
								information necessary for Covered Entity to meet the requirements of said section.
							</li>
							<li>Business Associate agrees that any Breach of Unsecured Protected Health Information
								shall be reported to Covered Entity promptly after the Business Associate becomes aware
								of said Breach, and under no circumstances later than one (1) business day thereafter.
								Business Associate further agrees that any compromise of Protected Health Information,
								other than a Breach of Unsecured Protected Health Information as specified in 2(c) of
								this Agreement, shall be reported to Covered Entity within ten (10) business days of
								discovering said compromise, or attempted compromise.
							</li>
							<li>Business Associate agrees to ensure that any Subcontractor, to whom Business Associate
								provides Protected Health Information, agrees to the same restrictions and conditions
								that
								apply through this Agreement to Business Associate with respect to such information.
								Business Associate further agrees that restrictions and conditions analogous to those
								contained herein shall be imposed on said Subcontractors via a written agreement that
								complies with all the requirements specified in § 164.504(e)(2), and that Business
								Associate shall only provide said Subcontractors Protected Health Information consistent
								with Section 13405(b) of the HITECH Act. Further, Business Associate agrees to provide
								copies of said written agreements to Covered Entity within ten (10) business days of a
								Covered Entity’s request for same.
							</li>
							<li>Business Associate agrees to provide access via in-app export, to Protected Health
								Information in a Designated Record Set to Covered Entity or, as directed by Covered
								Entity, to an Individual, in order to meet Covered Entity’s requirements under 45 CFR §
								164.524. Business Associate further agrees, in the case where Business Associate
								controls access to Protected Health Information in an Electronic Health Record, or
								controls access to Protected Health Information stored electronically in any format, to
								provide similar access in order for Covered Entity to meet its requirements of the HIPAA
								Rules and under Section 13405(c) of the HITECH Act. These provisions do not apply if
								Business Associate and its employees or Subcontractors have no Protected Health
								Information in a Designated Record Set of Covered Entity.
							</li>
							<li>Business Associate agrees to make Protected Health Information in a Designated Record
								Set available to the Covered Entity for the purpose of making amendments and
								incorporate such amendments in the Designated Record Set pursuant to 45 CFR
								§164.526. This provision does not apply if Business Associate and its employees or
								Subcontractors have no Protected Health Information from a Designated Record Set of
								Covered Entity.
							</li>
							<li>Unless otherwise protected or prohibited from discovery or disclosure by law, Business
								Associate agrees to make internal practices, books, and records, including policies and
								procedures (collectively “Compliance Information”), relating to the Use or Disclosure of
								Protected Health Information and the protection of same, available to the Covered Entity
								or to the Secretary for purposes of the Secretary determining Covered Entity’s
								compliance with the HIPAA Rules and the HITECH Act. Business Associate shall have a
								reasonable time within which to comply with requests for such access, consistent with
								this Agreement. In no case shall access, e required in less than ten (10) business days
								after Business Associate’s receipt of such request, unless otherwise designated by the
								Secretary.
							</li>
							<li>Business Associate agrees to maintain necessary and sufficient documentation of
								Disclosures of Protected Health Information as would be required for Covered Entity to
								respond to a request by an Individual for an accounting of such Disclosures, in
								accordance with 45 CFR §164.528.
							</li>
							<li>On request of Covered Entity, Business Associate agrees to provide to Covered Entity
								documentation made in accordance with this Agreement to permit Covered Entity to
								respond to a request by an Individual for an accounting of disclosures of Protected
								Health Information in accordance with 45 C.F.R. § 164.528. Business Associate shall
								provide
								said documentation in a manner and format to be specified by Covered Entity. Business
								Associate shall have a reasonable time within which to comply with such a request from
								Covered Entity and in no case shall Business Associate be required to provide such
								documentation in less than five (5) business days after Business Associate’s receipt of
								such request.
							</li>
							<li>Except as provided for in this Agreement, in the event Business Associate receives an
								access, amendment, accounting of disclosure, or other similar request directly from an
								Individual, Business Associate shall redirect the Individual to the Covered Entity.
							</li>
							<li>To the extent that Business Associate carries out one or more of Covered Entity’s
								obligations under the HIPAA Rules, the Business Associate must comply with all
								requirements of the HIPAA Rules that would be applicable to the Covered Entity.
							</li>
							<li>A Business Associate must honor all restrictions consistent with 45 C.F.R. § 164.522 that
								the Covered Entity or the Individual makes the Business Associate aware of, including
								the Individual’s right to restrict certain disclosures of protected health information to
								a health plan where the individual pays out of pocket in full for the healthcare item or
								service, in accordance with HITECH Act Section 13405(a).
							</li>
						</ol>
						
						<h3>3. Permitted Uses and Disclosures by Business Associate.</h3>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>Except as otherwise limited by this Agreement, Business Associate may make any Uses
								and Disclosures of Protected Health Information necessary to perform its services to
								Covered Entity and otherwise meet its obligations under this Agreement, if such Use or
								Disclosure would not violate the Privacy Rule, or the privacy provisions of the HITECH
								Act, if done by Covered Entity. All other Uses or Disclosures by Business Associate not
								authorized by this Agreement, or by specific instruction of Covered Entity, are
								prohibited.
							</li>
							<li>Except as otherwise limited in this Agreement, Business Associate may Use Protected
								Health Information for the proper management and administration of the Business
								Associate or to carry out the legal responsibilities of the Business Associate.
							</li>
							<li>Except as otherwise limited in this Agreement, Business Associate may Disclose
								Protected Health Information for the proper management and administration of the
								Business Associate, provided that Disclosures are Required By Law, or Business
								Associate obtains reasonable assurances from the person to whom the information is
								Disclosed that it will remain confidential and used, or further Disclosed, only as
								Required By Law, or for the purpose for which it was Disclosed to the person, and the
								person notifies the Business Associate of any instances of which it is aware in which the
								confidentiality of the information has been breached.
							</li>
							<li>Except as otherwise limited in this Agreement, Business Associate may Use Protected
								Health Information to provide Data Aggregation services to Covered Entity as permitted
								by 45 CFR §164.504(e)(2)(i)(B). Business Associate agrees that such Data Aggregation
								services shall be provided to Covered Entity only wherein said services pertain to Health
								Care Operations. Business Associate further agrees that said services shall not be
								provided in a manner that would result in Disclosure of Protected Health Information to
								another covered entity who was not the originator and/or lawful possessor of said
								Protected Health Information. Further, Business Associate agrees that any such wrongful
								Disclosure of Protected Health Information is a direct violation of this Agreement and
								shall be reported to Covered Entity immediately after the Business Associate becomes
								aware of said Disclosure and, under no circumstances, later than three (3) business days
								thereafter.
							</li>
							<li>Business Associate may Use Protected Health Information to report violations of law to
								appropriate Federal and State authorities, consistent with § 164.502(j)(1).
							</li>
							<li>Business Associate shall make Uses, Disclosures, and requests for Protected Health
								Information consistent with the Minimum Necessary principle as defined herein.
							</li>
						</ol>
						
						<h3>4. Obligations and Activities of Covered Entity.</h3>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>Covered Entity shall notify Business Associate of the provisions and any
								limitation(s) in its notice of privacy practices of Covered Entity in accordance with
								45 CFR § 164.520, to the extent that such provisions and limitation(s) may affect
								Business Associate’s Use or Disclosure of Protected Health Information.
							</li>
							<li>Covered Entity shall notify Business Associate of any changes in, or revocation of,
								permission by an Individual to use or disclose Protected Health Information, to the
								extent that the changes or revocation may affect Business Associate’s use or
								disclosure of Protected Health Information.
							</li>
							<li>Covered Entity shall notify Business Associate of any restriction to the use or
								disclosure of Protected Health Information that Covered Entity has agreed to in
								accordance with 45 CFR §164.522, and also notify Business Associate regarding
								restrictions that must be honored under section 13405(a) of the HITECH Act, to the
								extent that such restrictions may affect Business Associate’s Use or Disclosure of
								Protected Health Information.
							</li>
							<li>Covered Entity shall notify Business Associate of any modifications to accounting
								disclosures of Protected Health Information under 45 CFR § 164.528, made applicable
								under Section 13405(c) of the HITECH Act, to the extent that such restrictions may
								affect Business Associate’s use or disclosure of Protected Health Information.
							</li>
							<li>Business Associate shall provide information to Covered Entity via email or phone
								call, wherein such information is required to be provided to Covered Entity as agreed
								to by Business Associate in paragraph 2(d) of this Agreement. Covered Entity reserves
								the right to modify the manner and format in which said information is provided to
								Covered Entity, as long as the requested modification is reasonably required by
								Covered Entity to comply with the HIPAA Rules or the HITECH Act, and Business
								Associate is provided sixty (60) business days notice before the requested
								modification takes effect.
							</li>
							<li>Covered Entity shall not require Business Associate to Use or Disclose Protected
								Health Information in any manner that would not be permissible under the HIPAA Rules
								if done by the Covered Entity.
							</li>
						</ol>
						
						<h3>5. Term and Termination.</h3>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>Term. The Term of this Agreement shall be effective as of the date and time Covered
								Entity agrees to the Terms of Service for using MyHealthily’s Website, Software, and
								Services by creating an account, and shall terminate when all of the Protected Health
								Information provided by Covered Entity to Business Associate, or created or received by
								Business Associate on behalf of Covered Entity, is destroyed or returned to Covered
								Entity, or, if it is infeasible to return or destroy Protected Health Information,
								protections are extended to such information, in accordance with the termination
								provisions in this Agreement.
							</li>
							<li>Termination for Cause by Covered Entity. Upon Covered Entity’s knowledge of a
								material breach of this Agreement by Business Associate, Covered Entity shall give
								Business Associate written notice of such breach and provide reasonable opportunity for
								Business Associate to cure the breach or end the violation. Covered Entity may terminate
								this Agreement, and Business Associate agrees to such termination, if Business Associate
								has breached a material term of this Agreement and does not cure the breach or cure is
								not possible. If neither termination nor cure is feasible, Covered Entity shall report
								the violation to the Secretary.
							</li>
							<li>Termination for Cause by Business Associate. Upon Business Associate’s knowledge of
								a material breach of this Agreement by Covered Entity, Business Associate shall give
								Covered Entity notice via email of such breach and provide reasonable opportunity for
								Covered Entity to cure the breach or end the violation. Business Associate may terminate
								this Agreement, and Covered Entity agrees to such termination, if Covered Entity has
								breached a material term of this Agreement and does not cure the breach or cure is not
								possible. If neither termination nor cure is feasible, Business Associate shall report
								the violation to the Secretary.
							</li>
							<li>Effect of Termination.
								<ol>
									<li>Except as provided in paragraph (2) of this section, upon termination of this
										Agreement for any reason, Business Associate shall return or destroy all
										Protected
										Health Information received from, or created or received by Business Associate
										on behalf of Covered Entity. This provision shall also apply to Protected Health
										Information that is in the possession of Subcontractors of Business Associate.
										Business Associate shall retain no copies of the Protected Health Information.
									</li>
									<li>In the event that Business Associate determines that returning or destroying the
										Protected Health Information is infeasible, Business Associate shall provide to
										Covered Entity, within ten (10) business days, notification of the conditions
										that make return or destruction infeasible. Upon such determination, Business
										Associate shall extend the protections of this Agreement to such Protected Health
										Information and limit further uses and disclosures of such Protected Health
										Information to those purposes that make the return or destruction infeasible, for
										so long as Business Associate maintains such Protected Health Information.
									</li>
								</ol>
							</li>
						</ol>
						
						<h3>6. Entire Agreement.</h3>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>This Agreement may be modified only by a signed written agreement between Covered
								Entity and Business Associate.
							</li>
							<li>All other agreements entered into between Covered Entity and Business Associate, not
								related to this Subject Matter, remain in full force and effect.
							</li>
						</ol>
						
						<h3>7. Governing Law & Dispute Resolution.</h3>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>This Agreement and the rights of the parties shall be governed by and construed in
								accordance with the Federal Arbitration Act, Federal law as it pertains to the Subject
								Matter, and shall be governed by and construed in accordance with the laws of the State
								of New Jersey as it pertains to contract formation and interpretation, without giving
								effect to its conflict of laws.
							</li>
							<li>In the event of a Dispute between you and MyHealthily (including any dispute over the
								validity, enforceability, or scope of this dispute resolution provision), other than with
								respect to claims for injunctive relief, the Dispute will be resolved by binding
								arbitration pursuant to the rules of the American Arbitration Association Commercial
								Arbitration Rules. The place of the arbitration shall be in Los Angeles, California. In
								the event that there is any Dispute between you and MyHealthily that is determined not to
								be subject to arbitration pursuant to the preceding sentence, you agree to submit in that
								event to the exclusive jurisdiction and venue of the state and federal courts located in
								the County of Bergen, New Jersey.
							</li>
						</ol>
						
						<h3>8. Miscellaneous.</h3>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>Regulatory References. A reference in this Agreement to a section in the Privacy Rule,
								Security Rule, or HITECH Act means the section as in effect or as amended.
							</li>
							<li>IAmendment. The Parties agree to take such action as is necessary to amend this
								Agreement from time to time as is necessary for Covered Entity and Business Associate
								to comply with the requirements of the Privacy Rule, Security Rule, the Health Insurance
								Portability and Accountability Act of 1996 (Pub. L. No. 104-191), and the HITECH Act
								and its corresponding regulations.
							</li>
							<li>Survival. The respective rights and obligations of Business Associate under Section 5(d)
								of this Agreement shall survive the termination of this Agreement.</li>
							<li>Interpretation. Any ambiguity in this Agreement shall be resolved to permit Covered
								Entity and Business Associate to comply with the Privacy Rule, Security Rule, the Health
								Insurance Portability and Accountability Act of 1996 (Pub. L. No. 104-191), and the
								HITECH Act and its corresponding regulations.</li>
							<li>Severability. If any provision or provisions of this Agreement is/are determined by a
								court of competent jurisdiction to be unlawful, void, or unenforceable, this Agreement
								shall not be unlawful, void or unenforceable thereby, but shall continue in effect and be
								enforced as though such provision or provisions were omitted.</li>
						</ol>
					</div>
				</div>
			</div>
			<Helmet>
				<script src={`${process.env.PUBLIC_URL}js/custom.js`}></script>
				<script src={`${process.env.PUBLIC_URL}js/theme.init.js`}></script>
				<script src="https://cdn.pagesense.io/js/candorusa/60482c77eb8e406a9ccd3127bafff935.js"></script>
			</Helmet>
		</div>
	);
}

export default BAA;
