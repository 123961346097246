import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import AboutMyHealthily from "./pages/about-myhealthily";
import BookDemo from "./pages/book-demo";
import Carriers from "./pages/carriers";
import Careers from "./pages/careers";
import CaseStudies from "./pages/case-studies";
import ComplianceLegal from "./pages/compliance-legal";
import Contact from "./pages/contact";
import Faqs from "./pages/faqs";
import GetStarted from "./pages/get-started";
import Heartland from "./pages/heartland";
import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import HowItWorks from "./pages/how-it-works";
import MarketingSupport from "./pages/marketing-support";
import NotFound from "./components/not-found";
import PressReleases from "./pages/press-releases";
import Pricing from "./pages/pricing";
import PrivacyPolicy from "./pages/privacy-policy";
import Prosper from "./pages/prosper";
import ReleaseNotes from "./pages/release-notes";
import TeamBios from "./pages/team-bios";
import Wallit from "./pages/wallit";

/* Blog Section */
import Blog from "./pages/blog";
import UnlockingProfitablee from "./pages/blog/unlocking-profitable-healthcare-coverage";
import PreparingForOpenEnrollment from "./pages/blog/preparing-for-open-enrollment";
import HealthcareTerminology from "./pages/blog/healthcare-terminology";
import WhatIsaLevelFundedPlan from "./pages/blog/what-is-a-level-funded-plan";
import IllustrativeLevelFunding from "./pages/blog/illustrative-level-funding";
import CompositeVsAgeBandingPricing from "./pages/blog/composite-vs-age-banding-pricing";
import RulePart1 from "./pages/blog/80-20-rule-part-1";
import RulePart2 from "./pages/blog/80-20-rule-part-2";
import RulePart3 from "./pages/blog/80-20-rule-part-3";
import BrokersIncreaseRevenue2023 from "./pages/blog/brokers-increase-revenue-2023";
import WaysToGrow2023 from "./pages/blog/5-ways-to-grow-in-2023";
import StreamliningSmallGroupBenefits from "./pages/blog/streamlining-small-group-benefits";
import AgentsStrategiesForSuccess from "./pages/blog/agents-strategies-for-success";
import InsuranceConferences2024 from "./pages/blog/insurance-conferences-2024";
import PowerOfMyHealthily from "./pages/blog/power-of-myhealthily";
import CraftingInclusiveBenefits from "./pages/blog/crafting-inclusive-benefits";
import SecurityInformation from "./pages/blog/myhealthily-security-information";
import StreamliningBenefitsBrokerage from "./pages/blog/streamlining-benefits-brokerage";
import HealthRegulations2024 from "./pages/blog/navigating-health-insurance-regulations-2024";
import DynamicCompanyCulture from "./pages/blog/6-steps-dynamic-company-culture";
import CustomizationCapabilities from "./pages/blog/myhealthily-customization-capabilities";
import RealTimeQuoatingPowerhouse from "./pages/blog/real-time-quoting-powerhouse";
import EmployeeCensusYourBusiness from "./pages/blog/how-employee-census-changes-your-business";
import DecodingHealthBenefitEnrollments from "./pages/blog/decoding-health-benefit-enrollments";
import PaperlessBenefitsAdministration from "./pages/blog/paperless-benefits-administration";
import BAA from "./pages/business-associate-agreement";
import TermsAndConditions from "./pages/terms-and-conditions";

export const App = () => {
	return <div className={`body`}>
		<Header/>
		<Routes>
			<Route path="*" element={<NotFound/>}/>
			<Route path={"/about-myhealthily"} element={<AboutMyHealthily/>}/>
			<Route path={"/book-demo"} element={<BookDemo/>}/>
			<Route path={"/baa"} element={<BAA/>}/>
			<Route path={"/carriers"} element={<Carriers/>}/>
			<Route path={"/careers"} element={<Careers/>}/>
			<Route path={"/case-studies"} element={<CaseStudies/>}/>
			<Route path={"/compliance-legal"} element={<ComplianceLegal/>}/>
			<Route path={"/contact"} element={<Contact/>}/>
			<Route path={"/faqs"} element={<Faqs/>}/>
			<Route path={"/get-started"} element={<GetStarted/>}/>
			<Route path={"/heartland"} element={<Heartland/>}/>
			<Route path={"/"} element={<Home/>}/>
			<Route path={"home2"} element={<Home2/>}/>
			<Route path={"/how-it-works"} element={<HowItWorks/>}/>
			<Route path={"/marketing-support"} element={<MarketingSupport/>}/>
			<Route path={"/press-releases"} element={<PressReleases/>}/>
			<Route path={"/pricing"} element={<Pricing/>}/>
			<Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
			<Route path={"/prosper"} element={<Prosper/>}/>
			<Route path={"/release-notes"} element={<ReleaseNotes/>}/>
			<Route path={"/team-bios"} element={<TeamBios/>}/>
			<Route path={"/toc"} element={<TermsAndConditions/>}/>
			<Route path={"/wallit"} element={<Wallit/>}/>

			/* Blog Section */
			<Route path={"/blog"} element={<Blog/>}/>
			<Route path={"/blog/unlocking-profitable-healthcare-coverage"} element={<UnlockingProfitablee/>}/>
			<Route path={"/blog/preparing-for-open-enrollment"} element={<PreparingForOpenEnrollment/>}/>
			<Route path={"/blog/healthcare-terminology"} element={<HealthcareTerminology/>}/>
			<Route path={"/blog/what-is-a-level-funded-plan"} element={<WhatIsaLevelFundedPlan/>}/>
			<Route path={"/blog/illustrative-level-funding"} element={<IllustrativeLevelFunding/>}/>
			<Route path={"/blog/composite-vs-age-banding-pricing"} element={<CompositeVsAgeBandingPricing/>}/>
			<Route path={"/blog/80-20-rule-part-1"} element={<RulePart1/>}/>
			<Route path={"/blog/80-20-rule-part-2"} element={<RulePart2/>}/>
			<Route path={"/blog/80-20-rule-part-3"} element={<RulePart3/>}/>
			<Route path={"/blog/brokers-increase-revenue-2023"} element={<BrokersIncreaseRevenue2023/>}/>
			<Route path={"/blog/5-ways-to-grow-in-2023"} element={<WaysToGrow2023/>}/>
			<Route path={"/blog/streamlining-small-group-benefits"} element={<StreamliningSmallGroupBenefits/>}/>
			<Route path={"/blog/agents-strategies-for-success"} element={<AgentsStrategiesForSuccess/>}/>
			<Route path={"/blog/insurance-conferences-2024"} element={<InsuranceConferences2024/>}/>
			<Route path={"/blog/power-of-myhealthily"} element={<PowerOfMyHealthily/>}/>
			<Route path={"/blog/crafting-inclusive-benefits"} element={<CraftingInclusiveBenefits/>}/>
			<Route path={"/blog/myhealthily-security-information"} element={<SecurityInformation/>}/>
			<Route path={"/blog/streamlining-benefits-brokerage"} element={<StreamliningBenefitsBrokerage/>}/>
			<Route path={"/blog/navigating-health-insurance-regulations-2024"} element={<HealthRegulations2024/>}/>
			<Route path={"/blog/6-steps-dynamic-company-culture"} element={<DynamicCompanyCulture/>}/>
			<Route path={"/blog/myhealthily-customization-capabilities"} element={<CustomizationCapabilities/>}/>
			<Route path={"/blog/real-time-quoting-powerhouse"} element={<RealTimeQuoatingPowerhouse/>}/>
			<Route path={"/blog/how-employee-census-changes-your-business"} element={<EmployeeCensusYourBusiness/>}/>
			<Route path={"/blog/decoding-health-benefit-enrollments"} element={<DecodingHealthBenefitEnrollments/>}/>
			<Route path={"/blog/paperless-benefits-administration"} element={<PaperlessBenefitsAdministration/>}/>
			
		</Routes>
		<Footer/>
	</div>;
};
