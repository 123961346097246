import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { setDesc } from "../helpers/desc";
import { Helmet } from "react-helmet";

function Wallit() {
	useEffect(() => {
		setTitle(`Wallit Highlight Features | MyHealthily`);
		setDesc(``);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>Wallit Highlight Features</h1>
						</div>
					</div>
				</div>
			</section>
			
			{/* One Column */}
			<div className="container pt-3 pb-2">
				<div className="row pt-2">
					<div className="col">
					<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">What is Wallit? </h2>
                         <p>Wallit revolutionizes workplace commerce with an all-in-one platform for managing employee benefits, rewards, expenses and more. Recognize and reward your employees and independent contractors easily with cash-based rewards from Wallit.</p>
						 <p><a href="downloads/Wallit-Fact-Sheet.pdf" target="_new">Download Fact Sheet</a> (PDF)</p>
 					</div>
				</div>
			</div>

			{/* Two Columns */}
			<div className="container">
				<div className="row text-center text-md-start pt-4 pb-5 my-4">
					<div className="col-lg-6 mb-5 mb-lg-0" data-="fadeInRightShorter" data--delay="600">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Better Benefits Through Payment Innovation</h2>
						<p>Your team’s physical and mental health is as important as their financial health. Now you can offer all three and more with one solution from Wallit, all in one secure, easy-to-use platform.</p>
						<p><a href="downloads/Wallit-Rewards-Guide.pdf" target="_new">Download Brochure</a> (PDF)</p>
					</div>
					<div className="col-lg-6 mb-5 mb-lg-0" data-="fadeInRightShorter" data--delay="1000">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">The How-To Guide for Rewards and Recognition</h2>
						<p>Everything you need to know about sending and spending rewards for your entire organization.</p>
						<p><a href="downloads/Wallit-Rewards-Guide.pdf" target="_new">Download Guide</a> (PDF)</p>
					</div>
				</div>
			</div>

			<div className="container pb-2">
				<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Pricing</a>
			</div>
			
			{/* Call to action begins */}
			<section className="call-to-action call-to-action-default with-button-arrow call-to-action-in-footer">
				<div className="container">
					<div className="row">
						<div className="col-sm-9 col-lg-9">
							<div className="call-to-action-content">
								<h3>Schedule a Demo</h3>
								<p className="mb-0">Give us a few minutes to walk you through our platform, answer questions, and get you signed up!</p>
							</div>
						</div>
						<div className="col-sm-3 col-lg-3">
							<div className="call-to-action-btn">
								<a href="https://myhealthily.com/schedule-demo/"
								   className="btn btn-modern text-2 btn-primary">LET'S TALK</a><span
								className="arrow hlb d-none d-md-block cta-padding" data-="rotateInUpLeft"></span>
							</div>
						</div>
					</div>
				</div>
			</section>

		<Helmet>
			<script src="https://cdn.pagesense.io/js/candorusa/60482c77eb8e406a9ccd3127bafff935.js"></script>
		</Helmet>
		
		</div>
	);
}

export default Wallit;