import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Helmet } from "react-helmet";

function CaseStudies() {
	useEffect(() => {
		setTitle(`MyHealthily - Healthcare, Dental, Vision, and More!`);
	}, []);
	
	return (
		<div role="main" className="main">

				<section className="section section-overlay-opacity section-overlay-opacity-scale-7 border-0 m-0 hero-image">
					<div className="container py-5">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-6 text-center mb-5 mb-lg-0">
								<div className="d-flex flex-column align-items-center justify-content-center h-100">
									<h3 className="position-relative text-color-light text-5 line-height-5 font-weight-medium px-4 mb-2 appear-animation" data-appear-animation="fadeInDownShorterPlus" data-plugin-options="{'minWindowWidth': 0}">
										<span className="position-absolute right-100pct top-50pct transform3dy-n50 opacity-3">
											<img src="/img/slides/slide-title-border.png" className="w-auto appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="250" data-plugin-options="{'minWindowWidth': 0}" alt="" />
										</span>
										A ridiculously simple platform to
										<span className="position-absolute left-100pct top-50pct transform3dy-n50 opacity-3">
											<img src="/img/slides/slide-title-border.png" className="w-auto appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="250" data-plugin-options="{'minWindowWidth': 0}" alt="" />
										</span>
									</h3>
									<h1 className="text-color-light font-weight-extra-bold text-12 mb-2 appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="1300" data-plugin-options="{'minWindowWidth': 0}">Quote, Enroll, & Manage</h1>
									<p className="text-4 text-color-light font-weight-light opacity-7 mb-0" data-plugin-animated-letters data-plugin-options="{'startDelay': 3500, 'minWindowWidth': 0}">Small Group Benefits, Profitably</p>
								</div>
							</div>
							
						</div>
					</div>
				</section>

				<div className="home-intro" id="home-intro">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-8">
								<p>We enable agents & brokers to offer healthcare benefits to small groups.<span>Book a demo today to get started!</span></p>
							</div>
							<div className="col-lg-4">
								<div className="get-started text-start text-lg-end">
									<a href="book-demo.html" className="btn btn-primary btn-lg text-3 font-weight-semibold px-4 py-3">BOOK YOUR DEMO</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container">

					<div className="row pt-3 appear-animation" data-appear-animation="fadeInUpShorter">
						<div className="col-lg-7 pe-lg-5">
							<h2 className="font-weight-normal line-height-1">Profitable Small Group Benefits</h2>
							<p className="lead">MyHealthily is a total game changer for agents and brokers!</p>
							<p>Our proprietary software enables agents to offer affordable healthcare insurance and ancillary products to small businesses with 1 - 500+ employees.</p>

							<div className="row pt-4 my-4">
								<div className="col-lg-6">
									<div className="feature-box feature-box-style-2 mb-4">
										<div className="feature-box-icon">
											<i className="icons icon-umbrella text-color-primary"></i>
										</div>
										<div className="feature-box-info">
											<h4 className="font-weight-bold text-4 mb-0">More Carriers & Plans</h4>
											<p className="mb-4">Offer your clients thousands of plan options from more than 135 carriers and ancillary products in all 50 states.</p>
										</div>
									</div>
									<div className="feature-box feature-box-style-2 mb-4">
										<div className="feature-box-icon">
											<i className="icons icon-hourglass text-color-primary"></i>
										</div>
										<div className="feature-box-info">
											<h4 className="font-weight-bold text-4 mb-0">One 45-Minute Call</h4>
											<p className="mb-4">With a completed census, turn days and weeks of work into one 45-minute meeting with your client.</p>
										</div>
									</div>
									<div className="feature-box feature-box-style-2 mb-4 mb-lg-0">
										<div className="feature-box-icon">
											<i className="icons icon-wallet text-color-primary"></i>
										</div>
										<div className="feature-box-info">
											<h4 className="font-weight-bold text-4 mb-0">$399 Per Month</h4>
											<p className="mb-4">No contracts, cancel anytime! Once you'e enrolled 500 lives your monthly fee will be waived.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="feature-box feature-box-style-2 mb-4">
										<div className="feature-box-icon">
											<i className="icons icon-check text-color-primary"></i>
										</div>
										<div className="feature-box-info">
											<h4 className="font-weight-bold text-4 mb-0">Full Access</h4>
											<p className="mb-4">Benefit choices include fully insured, medically underwritten, and ancillary health insurance products.</p>
										</div>
									</div>
									<div className="feature-box feature-box-style-2 mb-4">
										<div className="feature-box-icon">
											<i className="icons icon-support text-color-primary"></i>
										</div>
										<div className="feature-box-info">
											<h4 className="font-weight-bold text-4 mb-0">Marketing Support</h4>
											<p className="mb-4">We provide marketing materials to send to your clients and prospects such as email templates, blogs,  and more.</p>
										</div>
									</div>
									<div className="feature-box feature-box-style-2">
										<div className="feature-box-icon">
											<i className="icons icon-emotsmile text-color-primary"></i>
										</div>
										<div className="feature-box-info">
											<h4 className="font-weight-bold text-4 mb-0">WOW Customer Service</h4>
											<p className="mb-4">We're here to help you and your clients resolve issues by providing full support.</p>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="col-lg-5">
							
							<img className="img-fluid" src="img/laptop-myhealthily.jpg" alt=""/>

							<hr className="my-5"/>

							<h4 className="font-weight-normal">Get Started</h4>
							<ul className="list list-icons list-icons-style-3 mt-3">
								<li><i className="fas fa-calendar"></i> Schedule a demo</li>
								<li><i className="far fa-user"></i> Attend your demo</li>
								<li><i className="far fa-check-circle"></i> Signup for MyHealthily</li>
								<li><i className="far fa-clock"></i> All in about 30-minutes</li>
								<a href="book-demo.html" className="btn btn-modern btn-primary btn-effect-1 mt-3">BOOK YOUR DEMO</a>
							</ul>
							
						</div>
					</div>

				</div>

				{/* Testimonial begins */}
				<div className="container">
					<div className="row my-3"></div>
					<div className="row my-5 pb-2">
						<div className="col-lg-4">
							<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
								<div className="card-body py-3 px-2">
									<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
										<div className="testimonial-author pb-3">
											<div className="testimonial-author-thumbnail">
												<img src="/img/logo-mfb.jpg" className="img-fluid rounded-circle" alt=""/>
											</div>
											<p><strong className="font-weight-bold">John Torres</strong><span>Executive Director - Maryland Farm Bureau</span></p>
										</div>
										<div className="px-4 mx-2 pt-2 pb-3">
											<h4 className="font-weight-semi-bold">Great Customer Service</h4>
											<p>Just got off the phone with your Broker representative. She was great. Mad props to her customer service skills.</p>
											<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
								<div className="card-body py-3 px-2">
									<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
										<div className="testimonial-author pb-3">
											<div className="testimonial-author-thumbnail">
												<img src="/img/logo-premier-homes.jpg" className="img-fluid rounded-circle" alt=""/>
											</div>
											<p><strong className="font-weight-bold">Michel Saadeh</strong><span>Premier Homes of Naples, LLC</span></p>
										</div>
										<div className="px-4 mx-2 pt-2 pb-3">
											<h4 className="font-weight-semi-bold">Took Care of Our Needs</h4>
											<p>Your company stepped up quickly to address our insurance needs in a challenging environment and with no time to spare. I appreciate your efforts and support and look forward to finalizing the coverage for my family in the next few days.</p>
											<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
								<div className="card-body py-3 px-2">
									<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
										<div className="testimonial-author pb-3">
											<div className="testimonial-author-thumbnail">
												<img src="/img/logo-chamber-of-commerce-cape-coral.jpg" className="img-fluid rounded-circle" alt=""/>
											</div>
											<p><strong className="font-weight-bold">Member</strong><span>Chamber of Commerce of Cape Coral</span></p>
										</div>
										<div className="px-4 mx-2 pt-2 pb-3">
											<h4 className="font-weight-semi-bold">Big Savings</h4>
											<p>We saw a 25% reduction in premium while moving from a $6,000 deductible to $0. We are excited for our members to use this benefit and save like us!</p>
											<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i><i className="fas fa-star text-color-primary text-2"></i></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Testimonial ended */}

				{/* Carriers */}
				<section className="call-to-action">
					<div className="container">
						<div className="row text-center pb-2 mt-4">
							<h4>135+ National Carriers</h4>
							<div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 5}}, 'autoplay': true, 'autoplayTimeout': 1500, 'dots': false}">
								<div><img className="img-fluid" src="/img/allstate.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/aflac.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/aetna.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/united.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/sidecar.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/principal.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/oscar.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/kaiser-permanente.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/humana.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/guardian-healthcare.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/friday-health-plans.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/cigna.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/careington.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/blue-cross.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/beam.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/anthem.jpg" alt=""/></div>
								<div><img className="img-fluid" src="/img/and-many-more.jpg" alt=""/></div>
							</div>
						</div>
					</div>
				</section>

				<section className="call-to-action call-to-action-default with-button-arrow call-to-action-in-footer">
					<div className="container">
						<div className="row">
							<div className="col-sm-9 col-lg-9">
								<div className="call-to-action-content">
									<h3>Get Started</h3>
									<p className="mb-0">Our advisors will answer your questions, and get you signed up in about 30-minutes.</p>
								</div>
							</div>
							<div className="col-sm-3 col-lg-3">
								<div className="call-to-action-btn">
									<a href="book-demo.html" className="btn btn-modern text-2 btn-primary">BOOK YOUR DEMO</a><span className="arrow hlb d-none d-md-block button-adjust" data-appear-animation="rotateInUpLeft"></span>
								</div>
							</div>
						</div>
					</div>
				</section>
			<Helmet>
				<script src={`${process.env.PUBLIC_URL}js/custom.js`}></script>
				<script src={`${process.env.PUBLIC_URL}js/theme.init.js`}></script>
				<script src="https://cdn.pagesense.io/js/candorusa/60482c77eb8e406a9ccd3127bafff935.js"></script>
			</Helmet>
			</div>
	);
}

export default CaseStudies;
