import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { setDesc } from "../helpers/desc";
import { Helmet } from "react-helmet";

function Pricing() {
	useEffect(() => {
		setTitle(`MyHealthily Pricing`);
		setDesc(`MyHealthily offers 2 tiers of our platform to choose from to meet the needs of agents, brokers, and account managers.`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>MyHealthily Pricing</h1>
						</div>
					</div>
				</div>
			</section>
			
			{/* Introp Copy */}
			<div className="container pt-3 pb-1">
				<div className="row pt-2">
					<div className="col">
	                    <p>MyHealthily offers 2 tiers of our platform to choose from to meet the needs of agents, brokers, and account managers. No contracts, and cancel anytime. Also, you can save more than 15% if you chose to subscribe annually. Review the tiers below!</p>
					</div>
				</div>
			</div>

			{/* Quote to Proposal Copy */}
			<div class="container">
				<div class="row py-5" data-appear-animation="fadeInRightShorter">
					<div class="col-md-8 pe-md-5 mb-5 mb-md-0">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Quote to Proposal​</h2>
						<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Empower Your Agency</p>
						<p class="lead">Our Quote to Proposal tier is designed to empower an agency with comprehensive tools for efficient quoting and proposal generation. Here's what you'll gain access to:</p>
						<ul>
							<li><strong>Unlimited Group Quoting:</strong> Seamlessly quote and print proposals for an unlimited number of groups, ensuring you can serve all your clients without restriction.</li>
							<li><strong>Nationwide Rate Access:</strong> Benefit from access to all 50 states' rates, ensuring you have the most current and competitive information at your fingertips.</li>
							<li><strong>ACA Medical Plans:</strong> Access Affordable Care Act (ACA) medical plans from every licensed carrier, offering you a wide range of options to meet the diverse needs of your clients.</li>
							<li><strong>Non-Underwritten & Medically Underwritten Carriers:</strong> Receive standard rates from 8 Level-Funded and Medically Underwritten carriers, giving you and your clients more flexibility and choice.</li>
							<li><strong>Comprehensive Ancillary Quotes:</strong> Get quotes for Dental, Vision, Short-Term Disability (STD), Long-Term Disability (LTD), Life, and Worksite benefits from multiple carriers nationwide.</li>
							<li><strong>Additional Users and Locations:</strong> Your agency can add additional users and office locations for a fee.</li>
						</ul>
					</div>
					<div class="col-md-4 px-5 px-md-3">
						{/* Quote to Proposal Table */}
						<div class="pricing-table row mb-4">
							<div>
								<div class="plan">
									<div class="plan-header bg-primary">
										<h3>Quote to Proposal</h3>
									</div>
									<div class="plan-price">
										<span class="price"><span class="price-unit">$</span>99</span>
										<label class="price-label">PER MONTH</label>
									</div>
									<div class="plan-features">
										<ul>
											<li>$999 Annually and Save</li>
											<li>1 User</li>
											<li>Unlimited Groups</li>
											<li>Unlimited Quoting</li>
											<li>Proposals</li>
											<li>Agency Branding</li>
										</ul>
									</div>
									<div class="plan-footer">
										<a href="https://app.myhealthily.com/shop/agency/signup" class="btn btn-dark btn-modern btn-outline py-2 px-4">Sign Up</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr class="solid my-5"/>
			</div>

			{/* Advanced Copy */}
			<div class="container">
				<div class="row py-5" data-appear-animation="fadeInRightShorter">
					<div class="col-md-8 pe-md-5 mb-5 mb-md-0">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Advanced​</h2>
						<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Quoting, Proposals, Enrollment, Benefit Administration & So Much More</p>
						<p class="lead">Our Advanced Tier is designed to provide up to 5 users with full access to our suite of features, each developed to streamline operations and bolster client engagement effectively. This tier encompasses the following offerings:</p>
						<ul>
							<li><strong>General Agents Commissions Sharing:</strong> As your client base expands and you achieve specific milestones in employee lives on the platform, you'll gain the opportunity to share in the General Agents Commissions received by MyHealthily.</li>
							<li><strong>Electronic Employee Health Questionnaires:</strong> Simplify the data collection process with electronic health questionnaires from six carriers, designed to make submissions quicker and more efficient.</li>
							<li><strong>Comprehensive Electronic Completion and Signature:</strong> Ensure all employer and employee paperwork is effortlessly completed and signed electronically, optimizing the enrollment process.</li>
							<li><strong>Contributions for Benefit Elections:</strong> Manage contributions for benefit elections smoothly, ensuring accurate and timely financial management.</li>
							<li><strong>Full Electronic Employee Enrollment:</strong> Facilitate a completely electronic enrollment process for employees, enhancing the user experience and efficiency.</li>
							<li><strong>Benefits Administration Dashboard:</strong> Oversee all aspects of benefits administration through a comprehensive dashboard, providing a clear overview and control.</li>
							<li><strong>Agency Branded Employee-Facing Application:</strong> Elevate your agency's brand with a custom-branded application for employees, featuring Lifestyle & Wellness, Incentives, Payroll Management, Expense Management, Personal Finance, and Member Engagement.</li>
							<li><strong>Additional Users and Locations:</strong> Your agency can add additional users and office locations for a fee.</li>
						</ul>
					</div>
					<div class="col-md-4 px-5 px-md-3">
						{/* Advanced Table */}
						<div class="pricing-table row mb-4">
							<div>
								<div class="plan">
									<div class="plan-header bg-primary">
										<h3>Advanced​</h3>
									</div>
									<div class="plan-price">
										<span class="price"><span class="price-unit">$</span>499</span>
										<label class="price-label">PER MONTH</label>
									</div>
									<div class="plan-features">
										<ul>
											<li>$4,999 Annually and Save</li>
											<li>Up to 5 Users</li>
											<li>Unlimited Groups</li>
											<li>Unlimited Quoting</li>
											<li>Proposals</li>
											<li>Agency Branding</li>
											<li>GA Revenue Sharing</li>
											<li>Level-Funding IMQ  capabilities</li>
											<li>Employee Class Identification</li>
											<li>Employer Contribution Strategy</li>
											<li>Enrollment</li>
											<li>Employee Shop</li>
											<li>HR Management</li>
											<li>Ben Admin</li>
										</ul>
									</div>
									<div class="plan-footer">
										<a href="https://app.myhealthily.com/shop/agency/signup" class="btn btn-dark btn-modern btn-outline py-2 px-4">Sign Up</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Testimonial begins */}
			<div className="container">
				<div className="row my-3"></div>
				<div className="row my-5 pb-2">
					<div className="col-lg-4">
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-ima.jpg" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">Jodi G.</strong><span>Senior Benefits Account Executive - ima</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Easy to Use Platform</h4>
										<p>The ability to quote and present health and life options to clients on a
											user-friendly platform is a game-changer! The Technology is ideal for us! It
											makes small accounts less cumbersome.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-deland-gibson.jpg" className="img-fluid rounded-circle"
												 alt=""/>
										</div>
										<p><strong className="font-weight-bold">Erin C.</strong><span>Account Executive - Deland, Gibson Insurance</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Super Time Saver</h4>
										<p>Improved efficiency on both the enrollment and renewal sides is easy to see.
											80% of our current clients would appreciate this. You quickly see competitive
											rates, and it’s simple. This platform cuts the amount we spend by at least
											70%! It turns a 7-hour job into a 45-minute call!</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-insurica.jpg" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">Gregg G.</strong><span>Virtual Sales Director/Advisor - Insurica</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Simplified the Process</h4>
										<p>What you’ve done here is taken something that could be hugely complex and time
											consuming and you have shaved 70% – 80% of that time and complexity out of
											it.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Testimonial ended */}
			
			

		<Helmet>
			<script src="https://cdn.pagesense.io/js/candorusa/60482c77eb8e406a9ccd3127bafff935.js"></script>
		</Helmet>
		
		</div>
	);
}

export default Pricing;