import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Helmet } from "react-helmet";

function HowItWorks() {
	useEffect(() => {
		setTitle(`How MyHealthily Works`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>How it Works</h1>
						</div>
					</div>
				</div>
			</section>
			
			<div className="container container-xl-custom py-1 my-1">
				<div className="row justify-content-center">
					<div className="col-xl-9 text-center">
						<h2 className="font-weight-bold text-11 appear-animation"
							data-appear-animation="fadeInUpShorter">MyHealthily is a Game Chamger!</h2>
						<p className="line-height-9 text-4 opacity-9 appear-animation"
						   data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">MyHealthily allows agents and brokers to profitably quote, enroll, and service small group clients in less than 45-minutes.</p>
					</div>
				</div>
			</div>
			
			<div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
				<div className="home-concept mt-5">
					<div className="container">
						
						<div className="row text-center">
							<span className="sun"></span>
							<span className="cloud"></span>
							<div className="col-lg-2 ms-lg-auto">
								<div className="process-image">
									<img src="img/health.jpg" alt=""/>
									<strong>Health</strong>
								</div>
							</div>
							<div className="col-lg-2">
								<div className="process-image process-image-on-middle">
									<img src="img/ancillary.jpg" alt=""/>
									<strong>Ancillary</strong>
								</div>
							</div>
							<div className="col-lg-2">
								<div className="process-image">
									<img src="img/profitably.jpg" alt=""/>
									<strong>Profitably</strong>
								</div>
							</div>
							<div className="col-lg-4 ms-lg-auto">
								<div className="project-image">
									<div id="fcSlideshow" className="fc-slideshow">
										<ul className="fc-slides">
											<li><a href="book-demo" aria-label=""><img
												className="img-fluid" src="img/circle-myhealthily.jpg"
												alt="Book a Demo"/></a></li>
											<li><a href="https://myhealthily.com/schedule-demo/" aria-label=""><img
												className="img-fluid" src="img/circle-laptop.jpg"
												alt="Book a Demo"/></a></li>
										</ul>
									</div>
									<strong className="our-work">MyHealthily</strong>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row pt-3 pb-5 mb-5 appear-animation" data-appear-animation="fadeInUpShorter"
					 data-appear-animation-delay="300">
					<div className="col-lg-8">
						<div className="row">
							<div className="col-sm-6">
								<div className="feature-box feature-box-style-2">
									<div className="feature-box-icon">
										<i className="icons icon-clock text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">One 45-Minute Call</h4>
										<p className="mb-4">With a completed census, turn days and weeks of work into one
											45-minute meeting with your client.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-list text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">More Carriers & Plans</h4>
										<p className="mb-4">Offer your clients thousands of plan options from more than
											135 carriers and ancillary products.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-screen-desktop text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">Easy-to-Use Platform</h4>
										<p className="mb-4">We’ve developed a straight-forward user experience that makes
											enrollment and client management a breeze.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="feature-box feature-box-style-2">
									<div className="feature-box-icon">
										<i className="icons icon-layers text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">Full Access</h4>
										<p className="mb-4">Use the only platform that quotes everything- worksite,
											disability, life, dental, vision, and major medical, all in one place.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-emotsmile text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">WOW Customer Service</h4>
										<p className="mb-4">We’re here to help you and your clients resolve issues by
											providing full support from enrollment and beyond.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-target text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">Marketing Support</h4>
										<p className="mb-4">We provide marketing materials to send to your clients and
											prospects such as email templates, blog articles, and more.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="accordion" id="accordion12">
							<div className="card card-default">
								<div className="card-header" id="collapse12HeadingOne">
									<h4 className="card-title m-0">
										<a className="accordion-toggle" data-bs-toggle="collapse"
										   data-bs-target="#collapse12One" aria-expanded="true"
										   aria-controls="collapse12One">Differentiate Your Agency</a>
									</h4>
								</div>
								<div id="collapse12One" className="collapse show" aria-labelledby="collapse12HeadingOne"
									 data-bs-parent="#accordion12">
									<div className="card-body">
										<p className="mb-0 pb-3">Stand out from your competitors with a technology
											platform that says yes when others say no. Offer a ridiculously simple way to
											quote, enroll, and manage small group benefits profitably.</p>
										<a href="https://myhealthily.com/schedule-demo/"
										   className="btn btn-modern btn-primary btn-effect-1 mt-32">SCHEDULE DEMO</a>
									</div>
								</div>
							</div>
							<div className="card card-default">
								<div className="card-header" id="collapse12HeadingTwo">
									<h4 className="card-title m-0">
										<a className="accordion-toggle" data-bs-toggle="collapse"
										   data-bs-target="#collapse12Two" aria-expanded="false"
										   aria-controls="collapse12Two"> Save 70% of Your Time</a>
									</h4>
								</div>
								<div id="collapse12Two" className="collapse" aria-labelledby="collapse12HeadingTwo"
									 data-bs-parent="#accordion12">
									<div className="card-body">
										<p className="mb-0">From online quoting, to binding, enrollment and services, our
											platform takes the paperwork out of the process. Renewals are a breeze too!
											Write business in one 45-minute call!</p>
									</div>
								</div>
							</div>
							<div className="card card-default">
								<div className="card-header" id="collapse12HeadingThree">
									<h4 className="card-title m-0">
										<a className="accordion-toggle" data-bs-toggle="collapse"
										   data-bs-target="#collapse12Three" aria-expanded="false"
										   aria-controls="collapse12Three">Plans for Everyone</a>
									</h4>
								</div>
								<div id="collapse12Three" className="collapse" aria-labelledby="collapse12HeadingThree"
									 data-bs-parent="#accordion12">
									<div className="card-body">
										<p className="mb-0">Coverage for full-time, part-time, and seasonal employees,
											including dental, vision, LTD, voluntary, hospital, cancer care, and
											more.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Small icons, title, copy */}
			<div className="container py-5">
				<div className="row pb-4 mt-2">
					<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-support text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<h4 className="font-weight-bold mb-2">Customer Support</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing <span className="alternative-font">metus.</span> elit. Quisque rutrum pellentesque imperdiet.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-layers text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<h4 className="font-weight-bold mb-2">Sliders</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-menu text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<h4 className="font-weight-bold mb-2">Buttons</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row pb-4">
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="800">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-doc text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">HTML5 / CSS3 / JS</h4>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="600">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-user text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">Icons</h4>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing <span className="alternative-font">metus.</span> elit. Quisque rutrum pellentesque imperdiet.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="400">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-screen-desktop text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">Lightbox</h4>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
								</div>
							</div>
						</div>
					</div>


			</div>

			{/* Large icons, title, copy */}
			<div className="container">
				<div className="row">
					<div className="featured-boxes featured-boxes-style-2">
						<div className="row">
							<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="700">
								<div className="featured-box featured-box-effect-4">
									<div className="box-content">
										<i className="icon-featured icon-screen-tablet icons text-color-primary bg-color-grey"></i>
										<h4 className="font-weight-bold">Mobile Apps</h4>
										<p className="px-3">Lorem ipsum dolor sit amt, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="900">
								<div className="featured-box featured-box-effect-4">
									<div className="box-content">
										<i className="icon-featured icon-layers icons text-color-light bg-color-primary"></i>
										<h4 className="font-weight-bold">Creative Websites</h4>
										<p className="px-3">Lorem ipsum dolor sit amt, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1100">
								<div className="featured-box featured-box-effect-4">
									<div className="box-content">
										<i className="icon-featured icon-magnifier icons text-color-primary bg-color-grey"></i>
										<h4 className="font-weight-bold">SEO Optimization</h4>
										<p className="px-3">Lorem ipsum dolor sit amt, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1500">
								<div className="featured-box featured-box-effect-4">
									<div className="box-content">
										<i className="icon-featured icon-screen-desktop icons text-color-light bg-color-primary"></i>
										<h4 className="font-weight-bold">Brand Solutions</h4>
										<p className="px-3">Lorem ipsum dolor sit amt, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1300">
								<div className="featured-box featured-box-effect-4">
									<div className="box-content">
										<i className="icon-featured icon-doc icons text-color-primary bg-color-grey"></i>
										<h4 className="font-weight-bold">HTML5 / CSS3 / JS</h4>
										<p className="px-3">Lorem ipsum dolor sit amt, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1100">
								<div className="featured-box featured-box-effect-4">
									<div className="box-content">
										<i className="icon-featured icon-menu icons text-color-light bg-color-primary"></i>
										<h4 className="font-weight-bold">Buttons</h4>
										<p className="px-3">Lorem ipsum dolor sit amt, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Feature - 3 images */}
			<section className="section section-height-3 bg-color-grey-scale-1 m-0 border-0">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-6 pb-sm-4 pb-lg-0 pe-lg-5 mb-sm-5 mb-lg-0">
								<h2 className="text-color-dark font-weight-normal text-6 mb-2">Who <strong className="font-weight-extra-bold">We Are</strong></h2>
								<p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit massa enim. Nullam id varius nunc. </p>
								<p className="pe-5 me-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius nunc. Vivamus bibendum magna ex, et faucibus lacus venenatis eget</p>
							</div>
							<div className="col-sm-8 col-md-6 col-lg-4 offset-sm-4 offset-md-4 offset-lg-2 position-relative mt-sm-5 image-padding-top">
								<img src="img/generic/generic-corporate-3-1.jpg" className="img-fluid position-absolute d-none d-sm-block appear-animation image-padding-1" data-appear-animation="expandIn" data-appear-animation-delay="300" alt="" />
								<img src="img/generic/generic-corporate-3-2.jpg" className="img-fluid position-absolute d-none d-sm-block appear-animation image-padding-2" data-appear-animation="expandIn" alt="" />
								<img src="img/generic/generic-corporate-3-3.jpg" className="img-fluid position-relative appear-animation mb-2" data-appear-animation="expandIn" data-appear-animation-delay="600" alt="" />
							</div>
						</div>
					</div>
				</section>

			{/* Carriers */}
			<section className="call-to-action">
				<div className="container">
					<div className="row text-center pb-2 mt-4">
						<h4>135+ National Carriers</h4>
						<div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 5}}, 'autoplay': true, 'autoplayTimeout': 1500, 'dots': false}">
							<div><img className="img-fluid" src="/img/allstate.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/aflac.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/aetna.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/united.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/sidecar.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/principal.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/oscar.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/kaiser-permanente.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/humana.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/guardian-healthcare.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/friday-health-plans.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/cigna.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/careington.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/blue-cross.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/beam.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/anthem.jpg" alt=""/></div>
							<div><img className="img-fluid" src="/img/and-many-more.jpg" alt=""/></div>
						</div>
					</div>
				</div>
			</section>
			
			{/* One Column */}
			<div className="container pt-3 pb-2">
				<div className="row pt-2">
					<div className="col">
					<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Title Here</h2>
                           <p>This Privacy Policy describes how My Healthily Insurance Solutions LLC ("My Healthily" or "We" or "Us" or "Our") uses and discloses the Personal Information you provide to us or that we collect when you use our websites, mobile applications (when available), software, platforms and services ("Services"). Before providing Personal Information to us, please review this Privacy Policy carefully.</p>
					</div>
				</div>
			</div>


			{/* Two Columns */}
			<div className="container">
				<div className="row text-center text-md-start pt-4 pb-5 my-4">
					<div className="col-lg-6 mb-5 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="600">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Group Coverage</h2>
						<ul className="list list-icons list-primary">
							<li><i className="fas fa-check"></i> I own a business</li>
							<li><i className="fas fa-check"></i> I employees between 2 – 500+ people</li>
							<li><i className="fas fa-check"></i> I’m looking to get healthcare for my employees</li>
							<li><i className="fas fa-check"></i> I’m looking to get healthcare for myself, my spouse and/or children</li>
						</ul>
						<a href="schedule.html" className="btn btn-modern btn-primary btn-effect-1 mt-32">CONTINUE</a>
					</div>
					<div className="col-lg-6 mb-5 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1000">
						<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">Individual & Family Coverage</h2>
						<ul className="list list-icons list-primary">
							<li><i className="fas fa-check"></i> I am looking to get healthcare for myself, my spouse and/or children (none of which are employeed by my business)</li>
						</ul>
						<a href="https://myhealthily.com/individuals/" className="btn btn-modern btn-primary btn-effect-1 mt-3">CONTINUE</a>
					</div>
				</div>
			</div>

			{/* Three Columns */}
			<div className="container">
				<div className="row mt-3 mb-5">
					<div className="col-md-4 appear-animation" data-appear-animation="fadeInLeftShorter"
						 data-appear-animation-delay="800">
						<h3 className="font-weight-bold text-4 mb-2">Schedule a Demo</h3>
						<p>Give us 30 minutes to walk you through a demo of our platform, answer questions, and get you
							signed up!</p>
						<a href="https://myhealthily.com/schedule-demo/"
						   className="btn btn-modern btn-primary btn-effect-1 mt-32">SCHEDULE DEMO</a>
					</div>
					<div className="col-md-4 appear-animation" data-appear-animation="fadeIn"
						 data-appear-animation-delay="600">
						<h3 className="font-weight-bold text-4 mb-2">MyHealthily</h3>
						<p>Our platform makes the day-to-day tasks of an agent easier. Our admin team gives your agency
							the support you need.</p>
						<a href="https://myhealthily.com/agents/"
						   className="btn btn-modern btn-primary btn-effect-1 mt-32">LEARN MORE</a>
					</div>
					<div className="col-md-4 appear-animation" data-appear-animation="fadeInRightShorter"
						 data-appear-animation-delay="800">
						<h3 className="font-weight-bold text-4 mb-2">FAQs</h3>
						<p>Get the answers to the most commonly asked questions about MyHealthily’s platform including
							pricing, commissions, and more.</p>
						<a href="https://myhealthily.com/agent-faqs/"
						   className="btn btn-modern btn-primary btn-effect-1 mt-32">VIEW FAQs</a>
					</div>
				</div>
			</div>

			{/* Four Columns */}
			<div className="container">
				<div className="row featured-boxes featured-boxes-style-4">
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="200">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Have an Account?</h4>
								<p className="mb-0">If you have a password, you can login to your dashboard.</p>
								<a href="https://app.myhealthily.com/account/sign-in" className="btn btn-modern btn-primary btn-effect-1 mt-3">Login</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation" data-appear-animation="fadeIn">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Complete Application</h4>
								<p className="mb-0">Start where you left off to complete your application.</p>
								<a href="https://app.myhealthily.com/shop/myhealthily?label=facp" className="btn btn-modern btn-primary btn-effect-1 mt-3">Resume</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation" data-appear-animation="fadeIn">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Create an Account</h4>
								<p className="mb-0">Sign up to shop plans, enroll, manage your benefits.</p>
								<a href="https://app.myhealthily.com/shop/myhealthily?label=facp" className="btn btn-modern btn-primary btn-effect-1 mt-3">Get Started</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="featured-box featured-box-primary featured-box-effect-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
							<div className="box-content px-4">
								<h4 className="font-weight-bold text-color-dark pb-1 mb-2">Reset Password</h4>
								<p className="mb-0">If you forgot or need to reset your password we can help.</p>
								<a href="https://app.myhealthily.com/account/recover" className="btn btn-modern btn-primary btn-effect-1 mt-3">Reset</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Gray background */}
			<div id="examples" className="container py-2">
				<div className="row">
					<div className="col">
						<div className="row">
							<div className="col pb-3">
								<h4>About Your Plan​</h4>
								<div className="alert alert-default">
									You are not joining a group healthcare plan that is part of an association.​  Our licensed advisors will help you narrow down the right coverage for you, based on your specific needs.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			{/* Testimonial begins */}
			<div className="container">
				<div className="row my-3"></div>
				<div className="row my-5 pb-2">
					<div className="col-lg-4">
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-ima.jpg" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">Jodi G.</strong><span>Senior Benefits Account Executive - ima</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Easy to Use Platform</h4>
										<p>The ability to quote and present health and life options to clients on a
											user-friendly platform is a game-changer! The Technology is ideal for us! It
											makes small accounts less cumbersome.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-deland-gibson.jpg" className="img-fluid rounded-circle"
												 alt=""/>
										</div>
										<p><strong className="font-weight-bold">Erin C.</strong><span>Account Executive - Deland, Gibson Insurance</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Super Time Saver</h4>
										<p>Improved efficiency on both the enrollment and renewal sides is easy to see.
											80% of our current clients would appreciate this. You quickly see competitive
											rates, and it’s simple. This platform cuts the amount we spend by at least
											70%! It turns a 7-hour job into a 45-minute call!</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-insurica.jpg" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">Gregg G.</strong><span>Virtual Sales Director/Advisor - Insurica</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Simplified the Process</h4>
										<p>What you’ve done here is taken something that could be hugely complex and time
											consuming and you have shaved 70% – 80% of that time and complexity out of
											it.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Testimonial ended */}
			
			{/* Call to action begins */}
			<section className="call-to-action call-to-action-default with-button-arrow call-to-action-in-footer">
				<div className="container">
					<div className="row">
						<div className="col-sm-9 col-lg-9">
							<div className="call-to-action-content">
								<h3>Schedule a Demo</h3>
								<p className="mb-0">Give us a few minutes to walk you through our platform, answer
									questions, and get you signed up!</p>
							</div>
						</div>
						<div className="col-sm-3 col-lg-3">
							<div className="call-to-action-btn">
								<a href="https://myhealthily.com/schedule-demo/"
								   className="btn btn-modern text-2 btn-primary">LET'S TALK</a><span
								className="arrow hlb d-none d-md-block cta-padding" data-appear-animation="rotateInUpLeft"></span>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Helmet>
				<script src={`${process.env.PUBLIC_URL}js/custom.js`}></script>
				<script src={`${process.env.PUBLIC_URL}js/theme.init.js`}></script>
				<script src="https://cdn.pagesense.io/js/candorusa/60482c77eb8e406a9ccd3127bafff935.js"></script>
			</Helmet>
		</div>
	);
}

export default HowItWorks;
