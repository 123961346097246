import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { Helmet } from "react-helmet";

function Prosper() {
	useEffect(() => {
		setTitle(`Prosper Benefits from MyHealthily`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>Prosper Benefits</h1>
						</div>
					</div>
				</div>
			</section>

			{/* One Column */}
			<div className="container pt-3 pb-2">
				<div className="row pt-2">
					<div className="col">
					<h2 className="font-weight-bold text-6 line-height-1 ls-0 mb-4">How Prosper Benefits Work</h2>
                           <p>Free with all level-funded plans, your clients can use these services and tools when they need to see a doctor, counselor, health consultant, or get a prescription filled. By using Prosper Benefits their insurance company does not receive claims, and in return saves them, their employees, and company money.</p>
					</div>
				</div>
			</div>
			
			{/* Small icons, title, copy */}
			<div className="container py-5">
				<div className="row pb-4 mt-2">
					<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-screen-smartphone text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<h4 className="font-weight-bold mb-2">Telemedicine</h4>
								<p>Powered by Teladoc, you now have 24/7 access to a doctor with a $0 visit fee for general medical issues. With Teladoc, you can talk to a doctor by phone or online video to get a diagnosis, treatment options and prescription, if medically necessary.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-compass text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<h4 className="font-weight-bold mb-2">Health Advocate Solutions</h4>
								<p>Healthcare is complicated. Personal Health Advocates help you navigate the insurance and healthcare systems. Everything from medical bills and insurance claims to clarifying benefits, answering questions, and much more.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400">
						<div className="feature-box feature-box-style-2">
							<div className="feature-box-icon">
								<i className="icons icon-basket text-color-primary"></i>
							</div>
							<div className="feature-box-info">
								<h4 className="font-weight-bold mb-2">Everyday Discounts</h4>
								<p>BDeals is your one-stop shop for exclusive discounts on brands and experiences you know and love. Browse over 40 different categories across 500+ merchants for endless opportunities to save, with new deals added weekly.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row pb-4">
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="800">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-support text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">Telephonic EAP</h4>
									<p>Call for help with depression, stress, family or relationship conflicts, substance abuse, debt, help finding services for your children or elderly parents, legal and financial concerns, grief, loss, and more.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="600">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-wallet text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">Prescription Discounts</h4>
									<p>Make sure you’re always getting the best deal on your prescriptions with deep discounts through New Benefits Rx. Save up to 85% on most prescriptions at 60,000 retail pharmacies nationwide and through home delivery.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="400">
							<div className="feature-box feature-box-style-2">
								<div className="feature-box-icon">
									<i className="icons icon-screen-desktop text-color-primary"></i>
								</div>
								<div className="feature-box-info">
									<h4 className="font-weight-bold mb-2">Activate Prosper Benefits</h4>
									<ol>
										<li>Go to <a href="https:mybenefitswork.com/" target="_new">mybenefitswork.com</a></li>
										<li>Download the app, or click “Continue.”</li>
										<li>Click the “Register” tab and enter the requested information.</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

			</div>

			<Helmet>
				<script src={`${process.env.PUBLIC_URL}js/custom.js`}></script>
				<script src={`${process.env.PUBLIC_URL}js/theme.init.js`}></script>
				<script src="https://cdn.pagesense.io/js/candorusa/60482c77eb8e406a9ccd3127bafff935.js"></script>
			</Helmet>
		</div>
	);
}

export default Prosper;
