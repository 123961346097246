import { useEffect } from "react";
import { setTitle } from "../helpers/title";
import { setDesc } from "../helpers/desc";
import { Helmet } from "react-helmet";

function TeamBios() {
	useEffect(() => {
		setTitle(`MyHealthily Leadership Bios`);
		setDesc(`MyHealthily’s executive management team is made up of industry veterans, leaders, investors, experts, geeks, musicians, gamers, and very kind people.`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>Leadership Bios</h1>
						</div>
					</div>
				</div>
			</section>

			<div class="container py-4">

				{/* Michael Malhame */}
				<div class="row">
					<div class="col-md-7 order-2">
						<div class="overflow-hidden">
							<h2 class="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0" data-appear-animation="maskUp" data-appear-animation-delay="300" id="michael-malhame">Michael Malhame</h2>
						</div>
						<div class="overflow-hidden mb-3">
							<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">CEO & Owner</p>
						</div>
						<p class="pb-3" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Michael Malhame is a seasoned professional in the insurance industry, currently serving as the Founder and CEO of MyHealthily. He also holds the position of President at <a href="https://mjmglobal.com/" target="_new">MJM Global Insurance Brokerage Group, Inc.</a>, a role he has maintained for over 27 years. Michael's background includes an MBA in Finance & Business Economics from Fordham Gabelli School of Business, and he has previously served as a Commercial Lines Underwriter at Chubb Insurance and Chief at Rockleigh Volunteer Fire Department. Michael has also been happily married for 25 years and together they have 4 children.</p>
						<hr class="solid my-4" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900"/>
						<div class="row align-items-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div class="col-lg-6">
								<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Previous Page</a>
							</div>
							<div class="col-sm-6 text-lg-end my-4 my-lg-0">
								<strong class="text-uppercase text-1 me-3 text-dark">MyHealthily</strong>
								<ul class="social-icons float-lg-end">
									<li class="social-icons-facebook"><a href="https://www.facebook.com/MyHealthily/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
									<li class="social-icons-twitter"><a href="https://twitter.com/myhealthily" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
									<li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/myhealthily/mycompany/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
									<li class="social-icons-instagram"><a href="https://www.instagram.com/myhealthily/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-5 order-md-2 mb-4 mb-lg-0" data-appear-animation="fadeInRightShorter">
						<img src="img/michael-malhame.jpg" class="img-fluid" alt=""/>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<hr class="solid my-5"/>
					</div>
				</div>

				{/* Ben Levitan */}
				<div class="row">
					<div class="col-md-5 order-md-2 mb-4 mb-lg-0" data-appear-animation="fadeInLeftShorter">
						<img src="img/ben-levitan.jpg" class="img-fluid" alt=""/>
					</div>
					<div class="col-md-7 order-2">
						<div class="overflow-hidden">
							<h2 class="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0" data-appear-animation="maskUp" data-appear-animation-delay="300" id="ben-levitan">Ben Levitan</h2>
						</div>
						<div class="overflow-hidden mb-3">
							<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Strategic Advisor</p>
						</div>
						<p class="pb-3" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Ben is a four-time CEO and Venture Capitalist with more than 25 years of business experience across a range of industries.  Ben is passionate about entrepreneurs and helping business owners.  Ben is a graduate of Union College, a member of YPO, and a Trustee of Spaulding Rehabilitation Hospital.</p>
						<hr class="solid my-4" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900"/>
						<div class="row align-items-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div class="col-lg-6">
								<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Previous Page</a>
							</div>
							<div class="col-sm-6 text-lg-end my-4 my-lg-0">
							<strong class="text-uppercase text-1 me-3 text-dark">MyHealthily</strong>
								<ul class="social-icons float-lg-end">
									<li class="social-icons-facebook"><a href="https://www.facebook.com/MyHealthily/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
									<li class="social-icons-twitter"><a href="https://twitter.com/myhealthily" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
									<li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/myhealthily/mycompany/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
									<li class="social-icons-instagram"><a href="https://www.instagram.com/myhealthily/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<hr class="solid my-5"/>
					</div>
				</div>

				{/* Jeremy McLendon */}
				<div class="row">
					<div class="col-md-7 order-2">
						<div class="overflow-hidden">
							<h2 class="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0" data-appear-animation="maskUp" data-appear-animation-delay="300" id="jeremy-mclendon">Jeremy McLendon</h2>
						</div>
						<div class="overflow-hidden mb-3">
							<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Vice President</p>
						</div>
						<p class="pb-3" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Jeremy McLendon began his insurance career in 2004 and takes pride in navigating the everchanging landscape of the health insurance industry and identifying solutions to the everyday problems that plague Brokers in today’s world. Jeremy, a native of Georgia, graduated from Berry College in 2004 with a BA in Public Policy. As a well-balanced Insurance expert and prior agency owner, Jeremy has first-hand experience in the everyday hurdles that brokers experience.  Jeremy joined MyHealthily at its inception and has accepted the challenge of identifying opportunities for innovation and fostering partnerships with market leaders and up and coming disruptors.</p>
						<hr class="solid my-4" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900"/>
						<div class="row align-items-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div class="col-lg-6">
								<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Previous Page</a>
							</div>
							<div class="col-sm-6 text-lg-end my-4 my-lg-0">
							<strong class="text-uppercase text-1 me-3 text-dark">MyHealthily</strong>
								<ul class="social-icons float-lg-end">
									<li class="social-icons-facebook"><a href="https://www.facebook.com/MyHealthily/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
									<li class="social-icons-twitter"><a href="https://twitter.com/myhealthily" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
									<li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/myhealthily/mycompany/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
									<li class="social-icons-instagram"><a href="https://www.instagram.com/myhealthily/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-5 order-md-2 mb-4 mb-lg-0" data-appear-animation="fadeInRightShorter">
						<img src="img/jeremy-mclendon.jpg" class="img-fluid" alt=""/>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<hr class="solid my-5"/>
					</div>
				</div>

				{/* John Grothusen */}
				<div class="row">
					<div class="col-md-5 order-md-2 mb-4 mb-lg-0" data-appear-animation="fadeInLeftShorter">
						<img src="img/john-grothusen.jpg" class="img-fluid" alt=""/>
					</div>
					<div class="col-md-7 order-2">
						<div class="overflow-hidden">
							<h2 class="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0" data-appear-animation="maskUp" data-appear-animation-delay="300" id="john-grothusen">John Grothusen</h2>
						</div>
						<div class="overflow-hidden mb-3">
							<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Chief Financial Officer</p>
						</div>
						<p class="pb-3" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Prior to joining the  <a href="https://mjmglobal.com/" target="_new">MJM Global</a>/MyHealthily team in November of 2006 John attended Jersey City State College and worked in transportation for 25 years. He was able to start with MJM Global in the accounting department and worked his way up to becoming Chief Financial Officer. John’s goal is to help MyHealthily succeed and grow as a company. While building his career John got married and had children. He truly enjoys spending his quality time with his family and friends.</p>
						<hr class="solid my-4" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900"/>
						<div class="row align-items-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div class="col-lg-6">
								<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Previous Page</a>
							</div>
							<div class="col-sm-6 text-lg-end my-4 my-lg-0">
							<strong class="text-uppercase text-1 me-3 text-dark">MyHealthily</strong>
								<ul class="social-icons float-lg-end">
									<li class="social-icons-facebook"><a href="https://www.facebook.com/MyHealthily/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
									<li class="social-icons-twitter"><a href="https://twitter.com/myhealthily" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
									<li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/myhealthily/mycompany/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
									<li class="social-icons-instagram"><a href="https://www.instagram.com/myhealthily/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<hr class="solid my-5"/>
					</div>
				</div>

				{/* Kelly Quinn */}
				<div class="row">
					<div class="col-md-7 order-2">
						<div class="overflow-hidden">
							<h2 class="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0" data-appear-animation="maskUp" data-appear-animation-delay="300" id="kelly-quinn">Kelly Quinn</h2>
						</div>
						<div class="overflow-hidden mb-3">
							<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Director of Operations</p>
						</div>
						<p class="pb-3" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Kelly Quinn is the Director of Operations at MyHealthily, where she has been working for nearly five years. She is A+ and Network + certified, with top skills in troubleshooting, vendor management, and VoIP. Kelly has a strong background in customer-facing technical support roles, project management, account implementation, business development, and sales. She is also experienced in software training and content creation, demonstrating excellent problem-solving skills and a high level of organization.</p>
						<hr class="solid my-4" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900"/>
						<div class="row align-items-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div class="col-lg-6">
								<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Previous Page</a>
							</div>
							<div class="col-sm-6 text-lg-end my-4 my-lg-0">
							<strong class="text-uppercase text-1 me-3 text-dark">MyHealthily</strong>
								<ul class="social-icons float-lg-end">
									<li class="social-icons-facebook"><a href="https://www.facebook.com/MyHealthily/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
									<li class="social-icons-twitter"><a href="https://twitter.com/myhealthily" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
									<li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/myhealthily/mycompany/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
									<li class="social-icons-instagram"><a href="https://www.instagram.com/myhealthily/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-5 order-md-2 mb-4 mb-lg-0" data-appear-animation="fadeInRightShorter">
						<img src="img/kelly-quinn.jpg" class="img-fluid" alt=""/>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<hr class="solid my-5"/>
					</div>
				</div>

				{/* John David Garletts */}
				<div class="row">
					<div class="col-md-5 order-md-2 mb-4 mb-lg-0" data-appear-animation="fadeInLeftShorter">
						<img src="img/john-david-garletts.jpg" class="img-fluid" alt=""/>
					</div>
					<div class="col-md-7 order-2">
						<div class="overflow-hidden">
							<h2 class="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0" data-appear-animation="maskUp" data-appear-animation-delay="300" id="john-david-garletts">John David Garletts</h2>
						</div>
						<div class="overflow-hidden mb-3">
							<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Senior Risk Manager</p>
						</div>
						<p class="pb-3" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">John David is licensed in all 50 states and takes great pride in assisting groups through their journey to find the healthcare coverage plan that best fits their needs. John David is a Savannah Georgia native and was fittingly born on St. Patrick’s Day! He also attended Hampden-Sidney College in Virginia and obtained dual degrees in Biology and Managerial Economics. John David joined MyHealthily at it’s inception and has accepted the challenge of identifying opportunities for groups to be better served by the benefits in which they enroll.</p>
						<hr class="solid my-4" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900"/>
						<div class="row align-items-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div class="col-lg-6">
								<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Previous Page</a>
							</div>
							<div class="col-sm-6 text-lg-end my-4 my-lg-0">
							<strong class="text-uppercase text-1 me-3 text-dark">MyHealthily</strong>
								<ul class="social-icons float-lg-end">
									<li class="social-icons-facebook"><a href="https://www.facebook.com/MyHealthily/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
									<li class="social-icons-twitter"><a href="https://twitter.com/myhealthily" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
									<li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/myhealthily/mycompany/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
									<li class="social-icons-instagram"><a href="https://www.instagram.com/myhealthily/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<hr class="solid my-5"/>
					</div>
				</div>

				{/* Zanson Watt */}
				<div class="row">
					<div class="col-md-7 order-2">
						<div class="overflow-hidden">
							<h2 class="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0" data-appear-animation="maskUp" data-appear-animation-delay="300" id="zanson-watt">Zanson Watt</h2>
						</div>
						<div class="overflow-hidden mb-3">
							<p class="font-weight-bold text-primary text-uppercase mb-0" data-appear-animation="maskUp" data-appear-animation-delay="500">Vice President of Software Engineering</p>
						</div>
						<p class="pb-3" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">First and foremost a technologist and jack of all trades. Zanson Watt serves as the VP of Software Engineering at MyHealthily and has 20 years of experience building and leading software development teams in the B2B Marketing, VOIP, and Healthcare industries. Known for his ability to simplify complex systems and innovate workflow, Zanson is led by his desire for the understanding of all things. He surrounds himself with brilliant and imaginative minds, leading by example while learning from others. Outside of his professional life, he has many hobbies and artistic outlets from photography to music production, is passionate about education in technology, and enjoys playing video games and riding roller coasters with his family.</p>
						<hr class="solid my-4" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900"/>
						<div class="row align-items-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div class="col-lg-6">
								<a href="javascript:history.back()" class="btn btn-modern btn-dark mt-3">Back to Previous Page</a>
							</div>
							<div class="col-sm-6 text-lg-end my-4 my-lg-0">
							<strong class="text-uppercase text-1 me-3 text-dark">MyHealthily</strong>
								<ul class="social-icons float-lg-end">
									<li class="social-icons-facebook"><a href="https://www.facebook.com/MyHealthily/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
									<li class="social-icons-twitter"><a href="https://twitter.com/myhealthily" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
									<li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/myhealthily/mycompany/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
									<li class="social-icons-instagram"><a href="https://www.instagram.com/myhealthily/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-5 order-md-2 mb-4 mb-lg-0" data-appear-animation="fadeInRightShorter">
						<img src="img/zanson-watt.jpg" class="img-fluid" alt=""/>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<hr class="solid my-5"/>
					</div>
				</div>


			</div>			
			
			{/* Call to action begins */}
			<section className="call-to-action call-to-action-default with-button-arrow call-to-action-in-footer">
				<div className="container">
					<div className="row">
						<div className="col-sm-9 col-lg-9">
							<div className="call-to-action-content">
								<h3>Schedule a Demo</h3>
								<p className="mb-0">Give us a few minutes to walk you through our platform, answer
									questions, and get you signed up!</p>
							</div>
						</div>
						<div className="col-sm-3 col-lg-3">
							<div className="call-to-action-btn">
								<a href="https://myhealthily.com/schedule-demo/"
								   className="btn btn-modern text-2 btn-primary">LET'S TALK</a><span
								className="arrow hlb d-none d-md-block cta-padding" data-appear-animation="rotateInUpLeft"></span>
							</div>
						</div>
					</div>
				</div>
			</section>

		<Helmet>
			<script src="https://cdn.pagesense.io/js/candorusa/60482c77eb8e406a9ccd3127bafff935.js"></script>
		</Helmet>
		
		</div>
	);
}

export default TeamBios;
