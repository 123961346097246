import { useState } from "react";

export const Faq = ({ title, children, initActive }) => {
	const [ active, setActive ] = useState(initActive || false);
	
	return <section className={`toggle ${active ? "active" : ""}`}>
		<a onClick={() => setActive(!active)} className="toggle-title">{title}</a>
		<div className="toggle-content" style={{display:'block', "max-height": active ? "100px" : "0px" }}>
			<p>{children}</p>
		</div>
	</section>;
};
